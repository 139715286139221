.App {
  text-align: center;
}

header h1 {
  margin-bottom: 0;
}

header {
  margin-bottom: 6rem;
}

canvas {
  background-color: white;
  width: 400px;
  height: 247px;
}

.instructions {
  text-align: left;
}
